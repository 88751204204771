import React, { useState } from 'react';
import ReactDOM from 'react-dom';

const FullscreenImage = ({ src }) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleClick = () => {
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  if (isOpen) {
    return ReactDOM.createPortal(
      <div className="fullscreen-overlay" onClick={handleClose}>
        <img src={src} alt="Fullscreen Image" className="fullscreen-image" />
      </div>,
      document.body
    );
  }

  return (
    <img src={src} alt="Image" onClick={handleClick} />
  );
};

export default FullscreenImage;
