/* eslint-disable no-undef */
import React, { useState, useEffect } from "react";
import axios from "axios";
import Search from "./search";
import FullscreenImage from "./fullscreen";
import ProductButton from "./subscribe-button";

const Products = () => {
  const [products, setProducts] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(36);
  const [categoryId, setCategoryId] = useState("НФ-000323");
  const [debugInfo, setDebugInfo] = useState("");


  

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const id = params.get("categoryId");
    if (id) {
      setCategoryId(id);
    }
  }, []);

  console.log(categoryId);

  // useEffect(() => {
  //   const fetchProducts = async () => {
  //     try {
  //       // const response = await axios.get('http://localhost:3001/api/products');
  //       const response = await axios.get('/products.json');
  //       setProducts(response.data.data);
  //     } catch (error) {
  //       console.error(error);
  //     }
  //   };
  //   fetchProducts();
  // }, [page, perPage]);

  useEffect(() => {
    const fetchProducts = async () => {
      try {
       // const apiUrl = `https://appsheet.tabakevich.de:8443/mb/hs/botapi/getprodyid?categoryId=${encodeURIComponent(categoryId)}`;

       const apiUrl = `https://tbkbot.tabakevich.de/api/getprodyid?categoryId=${categoryId}`;

      /*  if (categoryId) {
          apiUrl += `/getprodyid?categoryId=${categoryId}`;
          console.log('API URL:', apiUrl);
        } */

        const response = await axios.get(apiUrl, {
          headers: {
            'Content-Type': 'application/json; charset=UTF-8',
         //   Authorization: 'Basic Ym90OjZwRzl2Mi1w',
          },
        //  responseType: 'json', // Указываем тип ответа, если ожидаем JSON данные
        });


        console.log('API-URL- :', apiUrl);
        console.log('Response data:', response.data);
        if (response.data && Array.isArray(response.data.Products)) {
          setProducts(response.data.Products);
        } else {
          console.error('Unexpected response data format:', response.data);
        }

      } catch (error) {
        console.error(error);
      }
    };

    fetchProducts();
  }, [page, perPage, categoryId]);

  
  


  //const pageCount = Math.ceil(products.length / perPage);
  // Оновлений розрахунок кількості сторінок
  const pageCount = Math.ceil(
    products.filter((product) =>
      product.title.toLowerCase().includes(searchQuery.toLowerCase())
    ).length / perPage
  );

  const handlePageChange = (newPage) => {
    setPage(newPage);
  };

  // const handleSearch = (query) => {
  //   setSearchQuery(query);
  // };

  // Оновлений обробник пошуку
  const handleSearch = (query) => {
    setSearchQuery(query);
    setPage(1); // Скидаємо номер сторінки до 1 при пошуку
  };

  const filteredProducts = products
    .filter((product) =>
      product.title.toLowerCase().includes(searchQuery.toLowerCase())
    )
    .slice((page - 1) * perPage, (page - 1) * perPage + perPage);

  const renderPagination = () => {
    const items = [];
    const maxVisiblePages = 3; // Максимальна кількість видимих сторінок

    items.push(
      <a
        key="prev"
        href="#"
        onClick={() => handlePageChange(Math.max(page - 1, 1))}
      >
        &laquo;
      </a>
    );

    const startPage = Math.max(1, page - Math.floor(maxVisiblePages / 2));
    const endPage = Math.min(pageCount, startPage + maxVisiblePages - 1);

    if (startPage > 2) {
      items.push(
        <a
          key={1}
          href="#"
          className={`pagination-item${1 === page ? " active" : ""}`}
          onClick={(e) => {
            e.preventDefault();
            handlePageChange(1);
          }}
        >
          1
        </a>,
        <span key="dots1">...</span>
      );
    }

    for (let i = startPage; i <= endPage; i++) {
      items.push(
        <a
          key={i}
          href="#"
          className={`pagination-item${i === page ? " active" : ""}`}
          onClick={(e) => {
            e.preventDefault();
            handlePageChange(i);
          }}
        >
          {i}
        </a>
      );
    }

    if (endPage < pageCount - 1) {
      items.push(
        <span key="dots2">...</span>,
        <a
          key={pageCount}
          href="#"
          className={`pagination-item${pageCount === page ? " active" : ""}`}
          onClick={(e) => {
            e.preventDefault();
            handlePageChange(pageCount);
          }}
        >
          {pageCount}
        </a>
      );
    }

    items.push(
      <a
        key="next"
        href="#"
        onClick={() => handlePageChange(Math.min(page + 1, pageCount))}
      >
        &raquo;
      </a>
    );

    return <div className="pagination">{items}</div>;
  };

  const renderProduct = (product, index) => (
    <div key={product.id} className="product">
      <a href="#" className="product-link">
        <div className="item">
          {!product.availability && (
            <img src="img/sold.jpg" alt="Фото товару" className="inner" />
          )}
          <FullscreenImage src={product.imageUrl || "img/default-image.jpg"} />
          <div className="availability">
            {product.availability ? "В наличии" : "Нет в наличии"}
          </div>
        </div>
        <div className="product-title">{product.title}</div>
      </a>
      <div className="product-info">
        <div>
          <span className="product-price-text">Цена:</span>
          <span className="product-price"> {product.price} грн</span>
        </div>
        <div className="product-icons"></div>
      </div>
      <ProductButton product={product} tg={tg} categoryId={categoryId} />
      </div>
  );

  return (
    <div className="content">
      <div className="products">
        <Search handleSearch={handleSearch} value={searchQuery} />
        {filteredProducts.length > 0 ? (
          filteredProducts.map((product) => renderProduct(product))
        ) : (
          <div className="no-products-found">Товарів не знайдено</div>
          
        )}
      </div>
      <div className="pagination">{renderPagination()}</div>
      <pre>{debugInfo}</pre>  {/* Вывод отладочной информации */}
    </div>
  );
};

export default Products;
