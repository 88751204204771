import React, { useState } from "react";
import axios from "axios";

function ProductButton({ product, tg, categoryId }) {
  const [subscribed, setSubscribed] = useState(false);

  console.log(`LOGS: ${product.id}`);
  const handleButtonClick = () => {
    const forcedCategoryId = "%D0%9D%D0%A4-000323";
    if (tg !== undefined) {
      if (product.id && forcedCategoryId && tg.initDataUnsafe.user) {
        axios
          .get(
            `https://tbkbot.tabakevich.de/red/subscribe/?categoryId=${forcedCategoryId}&productId=${product.id}&userId=${tg.initDataUnsafe.user.id}`
          )
          .then(() => setSubscribed(true));
      } else {
        console.log(`not found productID: ${product.id} or forced categoryID: ${forcedCategoryId} or tg userID: ${tg.initDataUnsafe.user.id}`);
      }
    } else {
      console.log("not found telegram object");
    }
  };

  return (
    <>
      {subscribed ? (
        <button className="btn-gray"> ВЫ ПОДПИСАНЫ </button>
      ) : (
        <button className="btn" onClick={handleButtonClick}>
          ПОДПИСАТЬСЯ
        </button>
      )}
    </>
  );
}

export default ProductButton;
