function Search({ handleSearch, value }) {
  const handleChange = (e) => {
    handleSearch(e.target.value);
  };

  return (
    <div className="form-search" id="search_mini_form">
      <input
        id="search"
        type="text"
        autoComplete="off"
        name="q"
        value={value}
        className="input-text UI-SEARCH UI-NAV-INPUT"
        maxLength="128"
        onChange={handleChange}
      />
      <button
        type="submit"
        title="Поиск"
        className="button search-button minisearch-menubtn"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          fill="#555"
          className="bi bi-search"
          viewBox="0 0 16 16"
        >
          <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
        </svg>
      </button>
    </div>
  );
}

export default Search;
